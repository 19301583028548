import clsx from "clsx";
import classes from "./Door.module.scss";

export default function Door({ day, activeDay, onClick, zoomToElement }) {
  const handleClick = () => {
    onClick(day);
  };

  return (
    <div id={"door" + day} className={classes.doorOuter}>
      <div
        className={clsx(classes.doorFrame, day === activeDay && classes.open)}
        onClick={handleClick}
      >
        <div className={classes.doorOverlay}>
          <div className={classes.left}>
            <div className={classes.panel}></div>
          </div>
          <div className={classes.right}>
            <div className={classes.panel}></div>
          </div>
          <div className={classes.day}>{day}</div>
        </div>
        <div className={classes.doorInner}></div>
      </div>
    </div>
  );
}
