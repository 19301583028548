import Door from "./components/Door";
import classes from "./App.module.scss";
import background from "./assets/background.jpg";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useState } from "react";
import DoorModal from "./components/DoorModal";
import moment from "moment";

function App() {
  const [activeDay, setActiveDay] = useState(null);

  const handleDoorClick = (day, zoomToElement) => {
    const today = moment();
    const checkDay = moment(`2021-12-${day}`, "YYYY-MM-DD");

    if (today.isBefore(checkDay, "d")) {
      return;
    }

    setActiveDay(day);
    zoomToElement(document.querySelector("#door" + day));
  };

  const getDoors = (zoomToElement) => {
    const doors = [];
    for (let i = 1; i <= 24; i++) {
      doors.push(
        <Door
          onClick={(day) => {
            handleDoorClick(day, zoomToElement);
          }}
          activeDay={activeDay}
          key={i}
          day={i}
        />
      );
    }
    return doors;
  };

  return (
    <div
      className={classes.App}
      style={{ backgroundImage: `url(${background})` }}
    >
      <TransformWrapper minScale={0.5} initialScale={0.5} centerOnInit={true}>
        {({ zoomToElement }) => {
          return (
            <TransformComponent
              wrapperStyle={{ height: "100%", width: "100%" }}
              contentStyle={{ height: "300vw", width: "200vw" }}
            >
              <div className={classes.gridContainer}>
                {getDoors(zoomToElement)}
              </div>
            </TransformComponent>
          );
        }}
      </TransformWrapper>

      <DoorModal day={activeDay} onClose={() => setActiveDay(null)} />
    </div>
  );
}

export default App;
