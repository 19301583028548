import classes from "./DoorModal.module.scss";

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function DoorModal({ day, onClose }) {
  const paddedDay = pad(day, 2);

  return (
    day && (
      <div className={classes.modal}>
        <img alt="1" src={`/days/${paddedDay}_1.webp`} />
        <img alt="2" src={`/days/${paddedDay}_2.webp`} />
        <img alt="3" src={`/days/${paddedDay}_3.webp`} />

        <button className={classes.close} onClick={onClose}>
          Zurück
        </button>
      </div>
    )
  );
}
